import React, { useState } from 'react';
import './App.css';

function App() {
  const [formData, setFormData] = useState({
    application: 'Just Sudoku',
    title: '',
    message: '',
    email: '',
    phone: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch('https://formspree.io/f/xzzbbeev', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        alert('Form submitted successfully!');
        setFormData({
          application: 'Just Sudoku',
          title: '',
          message: '',
          email: '',
          phone: ''
        });
      } else {
        throw new Error('Form submission failed');
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <div className="App">
      <h1>Plancode Software Support Form</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="application">Select Application *</label>
          <select
            id="application"
            name="application"
            value={formData.application}
            onChange={handleChange}
            required
            className="form-input"
          >
            <option value="Just Sudoku">Just Sudoku</option>
            <option value="Kelimer">Kelimer</option>
            <option value="Plancode">Plancode Software Development Services</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div>
          <label htmlFor="title">Title *</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
            className="form-input"
          />
        </div>
        <div>
          <label htmlFor="message">Message *</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            className="form-input"
          ></textarea>
        </div>
        <div>
          <label htmlFor="email">Email *</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="form-input"
          />
        </div>
        <div>
          <label htmlFor="phone">Phone</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default App;
